import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useRouter } from "next/router";
import en from "../../locales/en";
import ar from "../../locales/ar";
import CloseIcon from "@mui/icons-material/Close";
import { arFont, enFont } from "@/styles/font";
import Image from "next/image";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 450,
  minWidth: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  border: "none",
  outline: "none",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",

  borderRadius: "20px",
};

export default function Popup() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    window.sessionStorage.setItem("shown", "true");
  };
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;

  React.useEffect(() => {
    if (!window.sessionStorage.getItem("shown")) {
      setOpen(true);
    }
  }, []);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            sx={{
              fontSize: "20px",
              position: "absolute",
              top: "18px",
              [locale === "en" ? "left" : "right"]: "18px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          <Box
            sx={{
              position: "absolute",
              top: "18px",
              left: locale == "ar" ? "18px" : "",
              right: locale == "en" ? "18px" : "",
              display:"flex",
              gap:1
            }}
          >
            <Image src="/uae-flag.svg" width={20} height={20} alt="uae flag" />
            <Image
              src="/egypt-flag.svg"
              width={20}
              height={20}
              alt="egypt flag"
            />
          </Box>

          <Image
            src="/ring.svg"
            width={80}
            height={80}
            alt="Ring"
            priority
            style={{ marginBottom: "10px" }}
          />
          <Typography
            id="popup-description"
            className={locale === "en" ? enFont.className : arFont.className}
            sx={{ textAlign: "center", mt: 2, color: "#686B7A" }}
          >
            {t.popup}
          </Typography>
          <Box
            component="img"
            loading="lazy"
            alt="line"
            src="line.png"
            sx={{
              width: "130%",
              height: "7px",
              position: "relative",
              top: "33px",
            }}
          />
        </Box>
      </Modal>
    </div>
  );
}
